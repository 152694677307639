import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios from 'axios'
import { toast } from 'sonner'

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: any) => {
        const notRetryStatusCode = [500, 401, 403]
        if (!axios.isAxiosError(error)) return true
        if (
          error.response?.status &&
          notRetryStatusCode.includes(error.response?.status)
        )
          return false
        return failureCount < 3
      },
    },
    mutations: {
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          const msgs: string[] =
            error?.response?.data?.message ||
            error?.response?.data?.errors ||
            []
          toast.error(
            <div className="flex flex-col gap-1">
              {msgs.map((msg) => (
                <p key={msg}>{msg}</p>
              ))}
            </div>,
          )
        } else {
          toast.error('Erro ao processar operação.')
        }
      },
    },
  },
})

export function ReactQueryProvider({ children }: React.PropsWithChildren) {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
