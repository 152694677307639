import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { useCallback, useMemo } from 'react'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { deleteTransaction } from '@/lib/react-query/requests/transactions/delete-transaction.ts'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'
import { formatMoney } from '@/lib/format-money.ts'
import { RadioGroupFormField } from '@/components/form/radio-group.tsx'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Tooltip } from '@/components/tooltip.tsx'
import { Trash } from 'lucide-react'

interface DialogRegisterDialogProps {
  transaction: TransactionsOutput['data'][0]
}

const CLOSE_DIALOG_ID = 'remove-transaction-close-action'

const formSchema = z.object({
  deleteMode: z.enum(['single', 'all', 'next']),
})

type IFormSchema = z.infer<typeof formSchema>

export function DeleteRegisterDialog({
  transaction,
}: DialogRegisterDialogProps) {
  const queryClient = useQueryClient()

  const form = useForm<IFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      deleteMode: 'single',
    },
  })

  const kindLabel = useMemo(() => {
    const kinds: Record<string, string> = {
      income: 'Receita',
      outcome: 'Despesa',
    }
    return kinds[transaction.kind] || ''
  }, [transaction])

  const { mutate: deleteRegister, isPending } = useMutation({
    mutationFn: (mode?: 'single' | 'all' | 'next') =>
      deleteTransaction(transaction.id, mode),
    onSuccess: async () => {
      toast.success('Transação removida com sucesso.')
      await queryClient.invalidateQueries({
        queryKey: [GET_TRANSACTIONS],
      })
      close()
    },
  })

  const handleDelete = useCallback(
    async (data: IFormSchema) => {
      if (
        transaction.repeat_transaction_id ||
        transaction.fixed_transaction_id
      ) {
        return deleteRegister(data.deleteMode)
      }

      return deleteRegister('single')
    },
    [
      deleteRegister,
      transaction.fixed_transaction_id,
      transaction.repeat_transaction_id,
    ],
  )

  const deleteModeOptions = useMemo(() => {
    const opts = [
      {
        label: 'Remover somente essa',
        value: 'single',
      },
      {
        label: 'Remover todas',
        value: 'all',
      },
    ]

    if (transaction.fixed_transaction_id) {
      opts.push({
        label: 'Remover esse e os próximos',
        value: 'next',
      })
    }

    return opts
  }, [transaction.fixed_transaction_id])

  const close = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ID)
    if (el) el.click()
  }, [])

  return (
    <AlertDialog>
      <Tooltip title={'Remover'}>
        <div>
          <AlertDialogTrigger asChild>
            <button>
              <Trash color={'red'} size={14} />
            </button>
          </AlertDialogTrigger>

          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Remover {kindLabel}</AlertDialogTitle>

              {!!transaction.repeat_transaction_id && (
                <AlertDialogDescription className="text-md text-red-600">
                  Atenção ! Essa é uma{' '}
                  <strong>{kindLabel.toLowerCase()}</strong> com repetição
                  configurada.
                </AlertDialogDescription>
              )}

              {!!transaction.fixed_transaction_id && (
                <AlertDialogDescription className="text-md text-red-600">
                  Atenção ! Essa é uma{' '}
                  <strong>{kindLabel.toLowerCase()}</strong> fixa.
                </AlertDialogDescription>
              )}
            </AlertDialogHeader>
            <Form {...form}>
              <form
                className={'flex flex-col gap-2'}
                onSubmit={form.handleSubmit(handleDelete)}
              >
                <span className="text-xl">
                  <strong>
                    {transaction?.title} -{' '}
                    {formatMoney(transaction?.value || 0)}
                  </strong>
                </span>

                {!!(
                  transaction.repeat_transaction_id ||
                  transaction.fixed_transaction_id
                ) && <span className="text-md">O que deseja fazer ?</span>}

                {!transaction.repeat_transaction_id &&
                  !transaction.fixed_transaction_id && (
                    <span className="text-lg">
                      Tem certeza que deseja remover a{' '}
                      <strong>{kindLabel.toLowerCase()}</strong> ?
                    </span>
                  )}

                {!!(
                  transaction.repeat_transaction_id ||
                  transaction.fixed_transaction_id
                ) && (
                  <RadioGroupFormField<IFormSchema>
                    name={'deleteMode'}
                    options={deleteModeOptions}
                  />
                )}
                <AlertDialogFooter>
                  <AlertDialogCancel id={CLOSE_DIALOG_ID}>
                    Cancelar
                  </AlertDialogCancel>
                  <ButtonLoading
                    className={cn(buttonVariants({ variant: 'destructive' }))}
                    isLoading={isPending}
                  >
                    Confirmar
                  </ButtonLoading>
                </AlertDialogFooter>
              </form>
            </Form>
          </AlertDialogContent>
        </div>
      </Tooltip>
    </AlertDialog>
  )
}
