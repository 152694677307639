import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { DotsVerticalIcon } from '@radix-ui/react-icons'
import { InfoIcon, List, Pencil, Trash } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import {
  AlertDialog,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx'
import { Dialog } from '@/components/ui/dialog.tsx'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { DeleteRegisterDialog } from '@/pages/credit_cards/components/delete-register-dialog.tsx'
import { UpdateCreditCardModal } from '@/pages/credit_cards/components/update-credit-card-modal.tsx'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@/components/tooltip.tsx'

interface ListItemProps {
  creditCard: CreditCardsOutput['data'][0]
}

export const ListItem = ({ creditCard }: ListItemProps) => {
  const navigate = useNavigate()

  return (
    <Card key={creditCard.id}>
      <CardHeader className="pb-3 flex justify-between flex-row items-center">
        <CardTitle>{creditCard.name}</CardTitle>
        <Dialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'ghost'} size={'icon'}>
                <DotsVerticalIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Ações</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Dialog>
                <DropdownMenuItem
                  onClick={() => {
                    navigate(`/cartoes_credito/${creditCard.id}/faturas`)
                  }}
                >
                  <List className="mr-2 h-4 w-4" />
                  <span>Acessar Faturas</span>
                </DropdownMenuItem>

                <DropdownMenuItem asChild>
                  <UpdateCreditCardModal
                    triggerElement={
                      <>
                        <Pencil className="mr-2 h-4 w-4" />
                        <span>Editar</span>
                      </>
                    }
                    creditCard={creditCard}
                  />
                </DropdownMenuItem>
              </Dialog>
              <AlertDialog>
                <DropdownMenuItem asChild>
                  <>
                    <AlertDialogTrigger
                      className={
                        'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                      }
                    >
                      <Trash color={'red'} className="mr-2 h-4 w-4" />
                      <span>Remover</span>
                    </AlertDialogTrigger>
                    <DeleteRegisterDialog creditCard={creditCard} />
                  </>
                </DropdownMenuItem>
              </AlertDialog>
            </DropdownMenuContent>
          </DropdownMenu>
        </Dialog>
      </CardHeader>

      <CardContent className={'flex flex-col gap-2'}>
        <div className="text-sm text-muted-foreground flex justify-between">
          <Tooltip title={creditCard.invoices.previous.period}>
            <div className={'flex gap-1 items-center'}>
              <span>Fatura fechada:</span>
              <InfoIcon size={14} color={'black'} />
            </div>
          </Tooltip>
          <span>
            {Intl.NumberFormat('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }).format(creditCard.invoices.previous.total)}
          </span>
        </div>
        <div className="text-sm text-muted-foreground flex justify-between">
          <Tooltip title={creditCard.invoices.current.period}>
            <div className={'flex gap-1 items-center'}>
              <span>Fatura em aberto:</span>
              <InfoIcon size={14} color={'black'} />
            </div>
          </Tooltip>
          <span>
            {Intl.NumberFormat('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }).format(creditCard.invoices.current.total)}
          </span>
        </div>
      </CardContent>
    </Card>
  )
}
