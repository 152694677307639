import { z } from 'zod'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { MoneyInput } from '@/components/form/money-input.tsx'
import { SelectField } from '@/components/form/select.tsx'
import { DatePickerForm } from '@/components/form/date-picker.tsx'
import { AccountsOutput } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { InputField } from '@/components/form/input.tsx'
import { SwitchFormField } from '@/components/form/switch.tsx'
import { TextareaField } from '@/components/form/textarea.tsx'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import { useMemo, useState } from 'react'
import { cn } from '@/lib/utils.ts'
import { addMonths, format, parseISO, subMonths } from 'date-fns'
import { CategoriesOutput } from '@/lib/react-query/requests/categories/get-categories.ts'
import { RadioGroupFormField } from '@/components/form/radio-group.tsx'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { ptBR } from 'date-fns/locale/pt-BR'

const transactionSchema = z
  .object({
    id: z.string().optional(),
    showCreditCardInput: z.boolean().optional().default(false),
    title: z
      .string({ required_error: 'Campo obrigatório' })
      .min(1, 'Campo obrigatório'),
    value: z
      .number({
        coerce: true,
      })
      .default(0),
    description: z.string().nullable().default(null),
    date: z.date({
      required_error: 'Campo obrigatório',
      invalid_type_error: 'Campo obrigatório',
    }),
    billing_date: z.string().optional().nullable(),
    kind: z.enum(['income', 'outcome'], { message: 'Campo inválido' }),
    user_category_id: z
      .string({ required_error: 'Campo obrigatório' })
      .min(1, 'Campo obrigatório'),
    user_account_id: z.string().nullable().default(null),
    user_credit_card_id: z.string().nullable().default(null),
    effected: z.boolean().default(false),
    is_fixed: z.boolean().default(false),
    repeat: z.boolean().default(false),
    repeat_times: z.number({ coerce: true }).default(0),
    repeat_period: z.enum(['month', 'year']).default('month'),
    edit_mode: z.enum(['single', 'group']).default('single'),
  })
  .superRefine((values, ctx) => {
    if (values.id && Number(values.value || 0) < 0.01) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['value'],
      })
    }

    if (values.showCreditCardInput && !values.billing_date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['billing_date'],
      })
    }

    if (!values.showCreditCardInput && !values.user_account_id) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['user_account_id'],
      })
    }

    if (values.showCreditCardInput && !values.user_credit_card_id) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['user_credit_card_id'],
      })
    }

    if (values.repeat && !values.repeat_times) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo obrigatório',
        path: ['repeat_times'],
      })
    }

    const allowed_enums = ['month', 'year']
    if (values.repeat && !allowed_enums.includes(values.repeat_period)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Campo inválido',
        path: ['repeat_period'],
      })
    }
  })

export type ITransactionFormType = z.infer<typeof transactionSchema>

interface TransactionFormProps {
  onSubmit: (data: ITransactionFormType) => void
  isLoading: boolean
  accounts: AccountsOutput['data']
  categories: CategoriesOutput['data']
  transaction: TransactionsOutput['data'][0] | null
  creditCards: CreditCardsOutput['data']
  showCreditCardInput?: boolean
  initialData: {
    kind: 'income' | 'outcome'
    creditCardId?: string
    accountId?: string
  }
}

export const TransactionForm = ({
  onSubmit,
  isLoading = false,
  accounts,
  categories,
  transaction,
  creditCards,
  showCreditCardInput,
  initialData,
}: TransactionFormProps) => {
  const form = useForm<ITransactionFormType>({
    resolver: zodResolver(transactionSchema),
    defaultValues: {
      id: transaction?.id ?? '',
      showCreditCardInput,
      title: transaction?.title ?? '',
      value: transaction?.value ?? 0,
      user_category_id: transaction?.category?.id ?? '',
      user_account_id:
        initialData?.accountId ?? transaction?.account?.id ?? null,
      date: transaction?.date ? parseISO(transaction.date) : new Date(),
      is_fixed: !!transaction?.fixed_transaction_id || false,
      repeat: !!transaction?.repeat_transaction_id || false,
      effected: transaction?.effected ?? false,
      description: transaction?.description ?? null,
      repeat_times: transaction?.total_installments || 3,
      repeat_period: 'month',
      billing_date: transaction?.billing_date
        ? format(parseISO(transaction.billing_date), 'yyyy-MM-dd')
        : null,
      edit_mode: 'single',
      user_credit_card_id:
        transaction?.credit_card?.id ?? initialData?.creditCardId ?? null,
      kind: transaction?.kind ?? initialData?.kind,
    },
  })

  const [expanded, setExpanded] = useState(false)

  const { id, repeat, is_fixed, date, user_credit_card_id } = form.watch()

  const billingDateOptions = useMemo(() => {
    const currentDate =
      transaction?.id && transaction.billing_date
        ? new Date(transaction.billing_date)
        : date

    if (!user_credit_card_id || !currentDate) return []

    const creditCard = creditCards.find(
      (item) => item.id === user_credit_card_id,
    )

    if (!creditCard) return []

    const defaultDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      creditCard.due_day,
    )

    const totalInstallments = transaction?.total_installments || 1
    const numOfOptions = Math.max(totalInstallments, 6)

    const optionsValues = Array.from({ length: numOfOptions }, (_, i) => {
      let currentDate = addMonths(defaultDate, i - 1)

      if (i < 1) {
        currentDate = subMonths(defaultDate, i + 1)
      }

      return currentDate
    })

    return optionsValues
      .sort((a, b) => {
        return a.getTime() - b.getTime()
      })
      .map((item) => ({
        label: format(item, "dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
        value: format(item, 'yyyy-MM-dd'),
      }))
  }, [
    creditCards,
    date,
    transaction?.billing_date,
    transaction?.id,
    transaction?.total_installments,
    user_credit_card_id,
  ])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('flex flex-col gap-4 w-[400px]', {
          'w-[750px]': expanded,
        })}
      >
        <div className={'flex gap-4'}>
          <div className={'w-[400px] flex flex-col gap-2'}>
            <InputField<ITransactionFormType>
              name="title"
              label="Nome"
              inputProps={{
                placeholder: 'Nome',
              }}
            />

            <MoneyInput<ITransactionFormType>
              name={'value'}
              label={'Valor'}
              placeholder={'Valor'}
            />

            <SelectField<ITransactionFormType>
              name={'user_category_id'}
              label={'Categoria'}
              placeholder={'Selecione a categoria'}
              options={categories.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />

            {showCreditCardInput ? (
              <SelectField<ITransactionFormType>
                name={'user_credit_card_id'}
                label={'Cartão de crédito'}
                placeholder={'Selecione o cartão de crédito'}
                options={creditCards.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            ) : (
              <SelectField<ITransactionFormType>
                name={'user_account_id'}
                label={'Conta'}
                placeholder={'Selecione a conta'}
                options={accounts.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            )}

            <DatePickerForm<ITransactionFormType>
              label="Data"
              name={`date`}
              placeholder="Data"
              calendarProps={{
                mode: 'single',
              }}
            />

            {showCreditCardInput && (
              <SelectField<ITransactionFormType>
                name={'billing_date'}
                disabled={!billingDateOptions.length}
                label={'Faturamento'}
                placeholder="Data de faturamento"
                options={billingDateOptions}
              />
            )}

            {!showCreditCardInput && (
              <SwitchFormField<ITransactionFormType>
                name={'effected'}
                label={'Efetivado'}
              />
            )}

            <TextareaField<ITransactionFormType>
              name={'description'}
              label={'Descrição'}
              inputProps={{
                className: 'resize-none h-[100px]',
              }}
            />

            {(!id ||
              transaction?.repeat_transaction_id ||
              transaction?.fixed_transaction_id) && (
              <div className={'flex justify-end '}>
                <Button
                  type={'button'}
                  variant={'ghost'}
                  className={'flex gap-2 text-orange-600'}
                  onClick={() => setExpanded((old) => !old)}
                >
                  <span>Mais opções</span>
                  {expanded ? <ArrowLeft /> : <ArrowRight />}
                </Button>
              </div>
            )}
          </div>

          {expanded && !id && (
            <div className={'w-[400px] flex flex-col gap-2'}>
              <SwitchFormField<ITransactionFormType>
                name={'is_fixed'}
                label={'Despesa Fixa'}
                disabled={repeat}
              />
              <SwitchFormField<ITransactionFormType>
                name={'repeat'}
                label={'Repetir'}
                disabled={is_fixed}
              />

              <div className={'flex w-full gap-2'}>
                <InputField<ITransactionFormType>
                  name="repeat_times"
                  label="Vezes"
                  formClassName={'w-1/2'}
                  inputProps={{
                    placeholder: 'Vezes',
                    type: 'number',
                    disabled: !repeat,
                  }}
                />

                <SelectField<ITransactionFormType>
                  name={'repeat_period'}
                  label={'Período'}
                  formClassName={'w-1/2'}
                  disabled={!repeat}
                  options={[
                    { label: 'Meses', value: 'month' },
                    { label: 'Anos', value: 'year' },
                  ]}
                />
              </div>
            </div>
          )}

          {expanded && id && (
            <div className={'w-[400px] flex flex-col gap-2'}>
              <RadioGroupFormField<ITransactionFormType>
                name={'edit_mode'}
                options={[
                  {
                    label: 'Editar somente este',
                    value: 'single',
                  },
                  {
                    label: 'Editar todos',
                    value: 'group',
                  },
                ]}
              />
            </div>
          )}
        </div>

        <ButtonLoading type={'submit'} isLoading={isLoading}>
          <span>Salvar</span>
        </ButtonLoading>
      </form>
    </Form>
  )
}
