import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { Form } from '@/components/ui/form.tsx'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { UploadFile } from '@/components/form/upload-file.tsx'
import {
  updateTransaction,
  UploadTransactionAttachments,
} from '@/lib/react-query/requests/transactions/update-transaction.ts'
import { toast } from 'sonner'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'
import { uploadFile } from '@/lib/common/s3/upload-file.ts'
import { Tooltip } from '@/components/tooltip.tsx'
import { Paperclip } from 'lucide-react'

const CLOSE_DIALOG_ELEMENT_ID = 'update-attachment-dialog-id'

interface UpdateAttachmentsDialogProps {
  transaction: TransactionsOutput['data'][0]
}

const attachmentsFormSchema = z.object({
  receipt_file: z.any(),
  invoice_file: z.any(),

  remove_receipt: z.boolean(),
  remove_invoice: z.boolean(),
})

type IAttachmentsFormSchema = z.infer<typeof attachmentsFormSchema>

export const UpdateAttachmentsDialog = ({
  transaction,
}: UpdateAttachmentsDialogProps) => {
  const form = useForm<IAttachmentsFormSchema>({
    resolver: zodResolver(attachmentsFormSchema),
    defaultValues: {
      receipt_file: null,
      invoice_file: null,
      remove_invoice: false,
      remove_receipt: false,
    },
  })

  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
    form.reset()
  }, [form])

  const { mutate: handleUpdateAttachments, isPending } = useMutation({
    mutationFn: async (data: IAttachmentsFormSchema) => {
      const payload: UploadTransactionAttachments = {
        invoice_url: data.remove_invoice ? null : undefined,
        receipt_url: data.remove_receipt ? null : undefined,
      }

      const uploadFields: {
        fileKey: keyof IAttachmentsFormSchema
        urlKey: string
      }[] = [
        {
          fileKey: 'invoice_file',
          urlKey: 'invoice_url',
        },
        {
          fileKey: 'receipt_file',
          urlKey: 'receipt_url',
        },
      ]

      for await (const uploadField of uploadFields) {
        if (data[uploadField.fileKey] instanceof File) {
          const url = await uploadFile(data[uploadField.fileKey])
          if (url) {
            Object.assign(payload, { [uploadField.urlKey]: url })
          }
        }
      }

      return updateTransaction<UploadTransactionAttachments>(
        transaction.id,
        payload,
      )
    },
    onSuccess: async () => {
      toast.success('Anexos atualizados com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_TRANSACTIONS],
      })
      closeModal()
    },
  })

  const { remove_receipt, remove_invoice, invoice_file, receipt_file } =
    form.watch()

  const onSubmit = useCallback(
    async (data: IAttachmentsFormSchema) => {
      handleUpdateAttachments(data)
    },
    [handleUpdateAttachments],
  )

  return (
    <Dialog onOpenChange={() => form.reset()}>
      <Tooltip title={'Anexos'}>
        <div>
          <DialogTrigger>
            <button>
              <Paperclip size={14} />
            </button>
          </DialogTrigger>
          <DialogContent closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}>
            <DialogHeader>
              <DialogTitle>Anexos - {transaction.title}</DialogTitle>
            </DialogHeader>

            <Form {...form}>
              <form
                className={'flex flex-col gap-4'}
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <div className={'flex gap-6'}>
                  <UploadFile
                    name={'invoice_file'}
                    url={transaction.invoice_url || ''}
                    label={'Fatura'}
                    onRemove={() => {
                      form.setValue('remove_invoice', true)
                    }}
                  />

                  <UploadFile
                    name={'receipt_file'}
                    url={transaction.receipt_url || ''}
                    label={'Comprovante'}
                    onRemove={() => {
                      form.setValue('remove_receipt', true)
                    }}
                  />
                </div>

                <ButtonLoading
                  disabled={
                    !remove_invoice &&
                    !remove_receipt &&
                    !invoice_file &&
                    !receipt_file
                  }
                  type={'submit'}
                  isLoading={isPending}
                >
                  <span>Salvar</span>
                </ButtonLoading>
              </form>
            </Form>
          </DialogContent>
        </div>
      </Tooltip>
    </Dialog>
  )
}
