import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'
import {
  updateTransaction,
  UpdateTransactionInput,
} from '@/lib/react-query/requests/transactions/update-transaction.ts'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { Tooltip } from '@/components/tooltip.tsx'
import {
  ITransactionFormType,
  TransactionForm,
} from '@/pages/transactions/components/transaction-form.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { useGetCategories } from '@/lib/react-query/requests/categories/get-categories.ts'
import { useGetCreditCards } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { format, parseISO } from 'date-fns'

const CLOSE_DIALOG_ELEMENT_ID = 'update-transaction-dialog-id'

interface UpdateTransactionModalProps {
  triggerElement: ReactNode
  transaction: TransactionsOutput['data'][0]
}

export const UpdateTransactionModal = ({
  triggerElement,
  transaction,
}: UpdateTransactionModalProps) => {
  const queryClient = useQueryClient()
  const { data: accounts } = useGetAccounts(1, 100)
  const { data: categories } = useGetCategories(1, 100, { name_order: 'asc' })
  const { data: creditCards } = useGetCreditCards(1, 100)

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleUpdateTransaction, isPending } = useMutation({
    mutationFn: (data: ITransactionFormType) => {
      const payload: UpdateTransactionInput = {
        kind: data.kind,
        date: format(parseISO(data.date.toISOString()), 'yyyy-MM-dd'),
        description: data.description ?? null,
        value: data.value,
        title: data.title,
        user_account_id: data.user_account_id,
        user_category_id: data.user_category_id,
        user_credit_card_id: data.user_credit_card_id || null,
        effected: data.effected,
        edit_group: data.edit_mode === 'group',
        billing_date: data.billing_date
          ? format(parseISO(data.date.toISOString()), 'yyyy-MM-dd')
          : null,
      }

      if (data.user_credit_card_id) {
        delete payload.effected

        if (data.billing_date) {
          Object.assign(payload, {
            billing_date: data.billing_date,
          })
        }
      }

      return updateTransaction(transaction.id, payload)
    },
    onSuccess: async () => {
      toast.success('Transação atualizada com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_TRANSACTIONS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: ITransactionFormType) => {
      handleUpdateTransaction(data)
    },
    [handleUpdateTransaction],
  )

  return (
    <Dialog>
      <Tooltip title={'Editar'}>
        <div>
          <DialogTrigger>{triggerElement}</DialogTrigger>
        </div>
      </Tooltip>
      <DialogContent
        className={'max-w-[800px] w-max'}
        closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}
      >
        <DialogHeader>
          <DialogTitle>Atualizar Transação</DialogTitle>
        </DialogHeader>

        <TransactionForm
          onSubmit={onSubmit}
          isLoading={isPending}
          accounts={accounts?.data || []}
          categories={categories?.data || []}
          creditCards={creditCards?.data || []}
          transaction={transaction}
          initialData={{
            kind: transaction.kind,
            creditCardId: transaction.credit_card?.id,
          }}
          showCreditCardInput={!!transaction?.credit_card}
        />
      </DialogContent>
    </Dialog>
  )
}
